<template>
  <div v-if="attemptedAffiliate" class="h-screen flex justify-center items-center">
    <div class="text-center">
      <h1 class="text-8xl">404</h1>
      <p class="text-2xl">Page not found.</p>
      <div class="mt-5">
        <router-link to="/" class="link text-lg">Take me back</router-link>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions } from 'vuex'
import Home from '../home'
import TCAssist from '../tc-assist/TCAssist.vue'
import Promo from '../Promo.vue'
import { SETUP_TYPE } from '../../../constants'

export default {
  data() {
    return {
      attemptedAffiliate: false
    }
  },
  async created() {
    const entries = await this.$contentful.getEntries({
      content_type: 'promoPage',
      include: 10,
      'fields.slug': this.$route.path.slice(1),
      limit: 1
    })

    if (entries.items.length > 0) {
      this.$router.addRoute({
        path: this.$route.path,
        component: Promo,
        props: { entry: entries.items[0] }
      })
      this.$router.replace(this.$route.path)
      return
    }

    try {
      const routePathArray = this.$route.path.slice(1).split('/')
      
      const isRootPath = routePathArray.length === 1
      const isTCAssistPath = routePathArray.length === 2 && routePathArray[0] === 'tc-assist'
      
      if (isRootPath || isTCAssistPath) {
        // Get the slug from the last segment of the path
        const slug = routePathArray[routePathArray.length - 1].toLowerCase()
        const affiliate = await this.$service.getAffiliateBySlug(slug)
        
        this.setAffiliate(affiliate)
        this.$router.addRoute({
          path: this.$route.path, 
          component: (() => {
              switch (true) {
                case isTCAssistPath: return TCAssist
                default: return Home
              }
            })(), 
            props: { 
              setupType: (() => {
                switch (true) {
                  case isTCAssistPath: return SETUP_TYPE.TC_ASSIST
                  default: return SETUP_TYPE.FUB_AGENT_REVAMP
                }
              })()
            } 
        })
        this.$router.replace(this.$route.path)
      } else {
        this.attemptedAffiliate = true
      }
    } catch  {
      this.attemptedAffiliate = true
      console.error('Could not retrieve affiliates')
    }
  },
  methods: {
    ...mapActions(['setAffiliate'])
  },
}
</script>