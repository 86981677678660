<template>
  <div v-html="rteHtml" class="contentful-rte-html" />
</template>
<script>
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';

export default {
  props: ['html', 'content'],
  computed: {
    rteHtml() {
      return this.content ? documentToHtmlString(this.content) : this.html
    }
  }
}
</script>
<style>
.contentful-rte-html {
  & p {
    @apply leading-7 my-2;
  }
  & ul {
    @apply list-disc;

    & ul, ol {
      @apply ml-10;
    }
  }
  & ol {
    @apply list-decimal;

    & ol, ul {
      @apply ml-10;
    }
  }
  & h1 {
    @apply text-3xl my-5;
  }
  & h2 {
    @apply text-2xl my-4;
  }
  & h3 {
    @apply text-xl my-3;
  }
  & a {
    @apply text-site-purple underline cursor-pointer;
  }
  & li {
    & p {
      @apply my-0;
    }
  }
  & hr {
    @apply my-5;
  }
}
</style>