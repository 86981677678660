import Vue from 'vue';
import VueRouter from 'vue-router';
import { GUIDE_CODES, SETUP_TYPE, COURSES } from './constants'

Vue.use(VueRouter);

import store from './store'

import Home from './components/pages/home'
import PageNotFound from'./components/pages/404'
import Training from'./components/pages/training'
import SetupForm from'./components/pages/setup-form'
import BalancePayment from'./components/pages/BalancePayment'
import Terms from'./components/pages/Terms'
import AgentReference from'./components/pages/agent-reference'
import GuideRedemptionForm from'./components/pages/GuideRedemptionForm'
import GuideView from'./components/pages/GuideView'
import KtsAi from './components/pages/KtsAi'
import Blog from './components/pages/blog'
import Post from './components/pages/blog/Post.vue'
import Lender from './components/pages/lender'
import LenderSignUp from './components/pages/lender/LenderSignUp'
import ManualSignup from './components/pages/setup-form/ManualSignup'
import ManualSetupData from './components/pages/setup-form/ManualSetupData'
import Workshop from './components/pages/Workshop'
import OfficeHours from './components/pages/office-hours/OfficeHours.vue'
import AffiliatePayout from './components/pages/AffiliatePayout'
import Discover from './components/pages/conversionly/Discover.vue'
import BookingConfirmation from './components/pages/booking-confirmation/BookingConfirmation.vue'
import TCAssist from './components/pages/tc-assist/TCAssist'
import TCAssistSignup from './components/pages/tc-assist/TCAssistSignup'
import Audit from './components/pages/Audit.vue'
import Creds from './components/pages/Creds.vue'
import CourseEnroll from './components/pages/CourseEnroll.vue'
import FourtySevenTasks from './components/pages/funnel/FourtySevenTasks.vue'
import Admin from './components/pages/Admin.vue'
import KathleenBlack from './components/pages/kathleen-black/KathleenBlack.vue'
import KathleenBlackSetupData from './components/pages/kathleen-black/KathleenBlackSetupData.vue'
import EmailTemplateFindReplace from './components/pages/admin-to-move/EmailTemplateFindReplace.vue'

import MassApply from './components/pages/admin-to-move/MassApply.vue'

const routes = [
  { path: "/", name: "home", component: Home, props: { setupType: SETUP_TYPE.FUB_AGENT_REVAMP } },
  { path: "/setup", redirect: { name: 'training', params: { slug: 'fub-revamp' } } },
  { path: "/training", redirect: { name: 'training', params: { slug: 'fub-revamp' } } },
  { path: "/training/:slug", name: "training", component: Training, props: true, meta: { internal: true } },
  { path: "/office-hours/:keeId", alias: "/group-training/:keeId", component: OfficeHours, props: (route) => ({ keeId: route.params.keeId, setupType: SETUP_TYPE.FUB_AGENT_REVAMP }) },
  { path: "/setup-form/:keeId?", component: SetupForm, alias: ['/sign-up/:keeId?', '/signup/:keeId?'], props: (route) => ({ keeId: route.params.keeId, setupType: SETUP_TYPE.FUB_AGENT_REVAMP }) },
  { path: "/setup-data/:keeId", component: ManualSetupData, props: true },
  { path: "/manual-signup", component: ManualSignup, meta: { internal: true } },
  { path: "/payment/balance/:keeId", component: BalancePayment, props: (route) => ({ keeId: route.params.keeId, setupType: SETUP_TYPE.FUB_AGENT_REVAMP }) },
  { path: "/texting-betty", redirect: "/" },
  { path: "/leadngage", redirect: "/" },
  { path: "/street-text", redirect: "/" },
  { path: "/kts-ai", name: "kts-ai", component: KtsAi },
  { path: "/agent-reference", name: "agent-reference", component: AgentReference, meta: { internal: true } },
  { path: "/terms/:type", name:"terms", component: Terms, meta: { internal: true }, props: true },
  { path: "/terms", redirect: "/terms/default" },
  { path: "/texting-setup-terms", redirect: "/terms/sms" },
  { path: "/lender", name: "lender", component: Lender, alias: "/lenders" },
  { path: "/lender/sign-up/:keeId?", alias: "/lender/signup/:keeId?", component: LenderSignUp, meta: { internal: true }, props: true },
  { path: "/solo", name: 'solo-agent-guide', alias: "/solo-agent-guide", component: GuideRedemptionForm, props: { guideType: GUIDE_CODES.SOLO } },
  { path: "/team-lead", name: 'team-lead-guide', component: GuideRedemptionForm, props: { guideType: GUIDE_CODES.TEAM_LEAD } },
  { path: "/guide/:slug", name: 'guide-view', component: GuideView, props: true, meta: { internal: true } },
  { path: "/blog", name: "blog", component: Blog, meta: { internal: true } },
  { path: '/post', redirect: { name: 'blog' }, meta: { internal: true } },
  { path: "/post/:slug", name: "post", component: Post, props: true, meta: { internal: true } },
  { path: "/boss-assist/office-hours/:keeId", alias: "/boss-assist/group-training/:keeId", component: OfficeHours, props: (route) => ({ keeId: route.params.keeId, setupType: SETUP_TYPE.BOSS_ASSIST }) },
  { path: "/workshop", name: "workshop", component: Workshop, meta: { internal: true } },
  { path: "/affiliate-payout/:keeId", component: AffiliatePayout, meta: { internal: true }, props: true },
  { path: "/discover", component: Discover },
  { path: "/booking-confirmation", component: BookingConfirmation, props: { setupType: SETUP_TYPE.FUB_AGENT_REVAMP } },
  { path: "/tc-assist", alias: "/tcassist", component: TCAssist },
  { path: "/tc-assist/sign-up", alias: "/tc-assist/signup", component: TCAssistSignup },
  { path: "/tc-assist/office-hours/:keeId", alias: "/tc-assist/group-training/:keeId", component: OfficeHours, props: (route) => ({ keeId: route.params.keeId, setupType: SETUP_TYPE.TC_ASSIST }) },
  { path: "/tc-assist/balance/:keeId", component: BalancePayment, props: (route) => ({ keeId: route.params.keeId, setupType: SETUP_TYPE.TC_ASSIST }) },
  { path: "/tc-assist/booking-confirmation", component: BookingConfirmation, props: { setupType: SETUP_TYPE.TC_ASSIST } },
  { path: "/audit", component: Audit },
  { path: "/fub-crash-course",  component: CourseEnroll, props: { course: COURSES.FUB_CRASH_COURSE } },
  { path: "/credentials/:keeId", component: Creds, props: true },
  { path: "/crm-tasks/:keeId", component: FourtySevenTasks, props: true },
  { path: "/task-assign", component: Admin, props: { portalPath: '/setup/task-assign' } },
  { path: "/mass-apply", alias: "/admin/mass-apply", component: MassApply },
  { path: "/crypto", alias: "/admin/crypto", component: Admin, props: { portalPath: '/crypto' } },
  { path: "/admin/subscription/cancel", component: Admin, props: { portalPath: '/subscriptions/cancel' } },
  { path: "/affiliate-list", alias: "/admin/payout-note", component: Admin, props: { portalPath: '/affiliates' } },
  { path: "/kathleen-black-ecosystem", component: KathleenBlack },
  { path: "/kathleen-black/setup-data/:keeId", component: KathleenBlackSetupData, props: true },
  { path: "/email-template-find-replace", component: EmailTemplateFindReplace },
  { path: "*", name: "page-not-found", component: PageNotFound, meta: { internal: true } }
];

const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior () {
    return { x: 0, y: 0 }
  }
})

router.beforeEach(async (to, from, next) => {
  const isInternalPage = to.matched.some(record => record.meta.internal)
  await store.dispatch('setBannerDisplay', !isInternalPage)
  next()
})

export default router;
