<template>
  <FormPageLayout>
    <p class="text-2xl font-semibold">Thanks for signing up!</p>
    <p class="text-lg mt-2">We will need the following information before we can start setting up your Follow Up Boss account.</p>

    <!-- Follow Up Boss Credentials -->
    <FormSection title="Follow Up Boss Credentials" helperText="(Owner Account)">
      <VTextField :v="v$.formModel.crmUsername" placeholder="Username" autocomplete="off" />
      <VTextField :v="v$.formModel.crmPassword" placeholder="Password" autocomplete="off" />
    </FormSection>

    <!-- Team Leader -->
    <FormSection title="Specify Team Leader/Manager/DOO">
      <p class="text-sm">These tasks are the safety net to check in and ensure the tasks and smart lists are being followed. The leader who you would like to be alerted when an agent does not meet accountability standards when calling past clients, COI, and VIP COI. This role will be notified per new client plan and if accountability is needed as tasks are missed specifically by the sales agents, but also for ops and the team overall. Assign this to the team manager of Director of Operations once you have one. This can also be assigned to a strong admin role, otherwise for a growing team, the team leader would be assigned these tasks to oversee.</p>
      <VTextField :v="v$.formModel.teamLeader" placeholder="Team Leader/Manager/DOO" />
    </FormSection>

    <!-- Contracts Management Partner -->
    <FormSection title="Contracts Management Partner">
      <p class="text-sm">Includes<br>Client Buyers or Seller Agreement to Closed<br>OR<br>Listing Co-ordinator: Listing to Firm Sale (Heavy Checklist Focus)<br>&<br>Transaction Co-ordinator: Firm Sale to Closed (Heavy Checklist Focus)<br>OR<br>Overall Admin/Ops if covering these aspects of the business as well</p>
      <VTextField :v="v$.formModel.contractsMgm" placeholder="Contracts Management Partner" />
    </FormSection>

    <!-- Lead Pool Pond Agents -->
    <FormSection title="Specify Lead Pool Pond Agents">
      <p class="text-sm">Team pool where leads are directed if agent or ISA assigned do not meet minimum contact and frequency protocols. This is a pool where new recruits, and agents looking to pick up extra business, can call in order to establish a relationship, whereby the lead would then be assigned to the new agent with a follow-up smart list assigned.</p>
      <p class="text-sm mt-2">Please list the agents that you would like to add to the lead pool pond. If you have more than one, please separate them with a comma.</p>
      <VTextArea :v="v$.formModel.leadPool" placeholder="Lead Pool Agent(s)" />
    </FormSection>

    <!-- Database Manager -->
    <FormSection title="Specify Database Manager">
      <p class="text-sm">A role used by larger teams to oversee the CRM, lead assignment protocols, including nomenclature of the tagging systems, stages, and overall systems in the database. They monitor accountability, source of business accuracy, and bring any issues with missed or incomplete tasks directly to the agents or to a Team Leader, Director of Sales or Operations. Otherwise this would fall with the overall Operations or administrative role on a smaller team.</p>
      <VTextField :v="v$.formModel.dbManager" placeholder="Database Manager" />
    </FormSection>

    <!-- Lead Conversion Partner -->
    <FormSection title="Do you have a Lead Conversion Partner? LCP/ISA">
      <p class="text-sm">This is ISA, Inside Sales Agent, an agent who only calls and nurtures leads to book face to face consultations for sales agents on the team</p>
      <VRadio :v="v$.formModel.usesLeadConversionPartner" />
    </FormSection>

    <!-- Specify Lead Conversion Partner -->
    <FormSection v-if="formModel.usesLeadConversionPartner === 'Yes'" title="Please Specify your Lead Conversion Partner">
      <VTextField :v="v$.formModel.leadConversionPartner" placeholder="LCP/ISA" />
    </FormSection>

    <!-- Admin / Front Desk -->
    <FormSection title="Specify Admin / Front Desk">
      <p class="text-sm">Basic Administrative Role, assist with incoming calls, provide overflow support to contract management and team leader/team manager, tidy office space, welcome clients to the office, call for feedback, track and facilitate client reviews/feedback, and overall an entry level admin position for larger teams, usually with their own office space.</p>
      <p class="text-sm mt-2">Create and Manage all Systems and oversee all administrative aspects of the agents day to day business.</p>
      <VTextField :v="v$.formModel.adminFrontDesk" placeholder="Admin / Front Desk" />      
    </FormSection>

    <FormSection title="Include the Ecosystem Power Up?">
      <p class="text-sm">Would you like to enhance your Kathleen Black Ecosystem with automated drip campaigns that seamlessly complement your active nurturing efforts?</p>
      <p class="mt-2">Base price ${{ pricing.BASE.toLocaleString('en') }}</p>
      <button @click="showPowerUpLearnMore = true" class="link mt-2">
        Learn about what's included
      </button>
      <VRadio :v="v$.formModel.includePowerUp" />
    </FormSection>

    <div v-if="formModel.includePowerUp === 'Yes'">
      <!-- Website URL(s) -->
      <FormSection title="Website URL">
        <div v-for="(website, idx) in formModel.websites" :key="`website_${idx}`">
          <input type="text" v-model="website.url" class="form-input" placeholder="http://mywebsite.com" />
          <font-awesome-icon v-if="idx !== 0" class="cursor-pointer ml-2" @click="removeWebsite(idx)" icon="fa-solid fa-times" />
        </div>
        <p class="mt-5 font-semibold text-sm">Have multiple websites?</p>
        <a @click="addWebsite" class="text-site-purple font-semibold mt-1 inline-block underline cursor-pointer">
          <font-awesome-icon icon="fa-solid fa-plus-circle" />
          Add Website
        </a>
      </FormSection>

      <!-- Online Reviews -->
      <FormSection title="Online Reviews" helperText="(Please provide any URLs to your online reviews i.e Google, Zillow, Facebook etc.)">
        <div v-for="(onlineReview, idx) in formModel.onlineReviews" :key="`online_review_${idx}`">
          <input type="text" v-model="onlineReview.url" class="form-input" placeholder="http://review-url.com" />
          <font-awesome-icon v-if="idx !== 0" class="cursor-pointer ml-2" @click="removeOnlineReview(idx)" icon="fa-solid fa-times" />
        </div>
        <p class="mt-5 font-semibold text-sm">Have more online review URLs?</p>
        <a @click="addOnlineReview" class="text-site-purple font-semibold mt-1 inline-block underline cursor-pointer">
          <font-awesome-icon icon="fa-solid fa-plus-circle" />
          Add Online Review URL
        </a>
      </FormSection>

      <!-- Brokerage -->
      <FormSection title="Brokerage">
        <VTextField :v="v$.formModel.brokerage" placeholder="i.e Keller Williams Realty" />
      </FormSection>

      <!-- Business Address -->
      <FormSection title="Business Address">
        <VTextField :v="v$.formModel.businessAddress.addressLine1" placeholder="Address Line 1" />
        <VTextField :v="v$.formModel.businessAddress.addressLine2" placeholder="Address Line 2" />
        <CountrySelect
          v-model="formModel.businessAddress.country"
          :errorMessage="v$.formModel.businessAddress.country.$error ? v$.formModel.businessAddress.country.$errors[0].$message : ''"
        />
        <VTextField :v="v$.formModel.businessAddress.city" placeholder="City" />
        <StateSelect
          v-model="formModel.businessAddress.state"
          :errorMessage="v$.formModel.businessAddress.state.$error ? v$.formModel.businessAddress.state.$errors[0].$message : ''"
          :showProvinces="formModel.businessAddress.country === 'Canada'"
        />
        <VTextField 
          :v="v$.formModel.businessAddress.zip" 
          :placeholder="formModel.businessAddress.country === 'Canada' ? 'Postal Code' : 'Zip Code'" 
        />
      </FormSection>

      <!-- Servicing Multiple Markets -->
      <FormSection title="Do you service more than one market?" helperText="(multiple states, provinces, or regions)">
        <VRadio :v="v$.formModel.multiMarketServicing" />
      </FormSection>

      <!-- YouTube Channel -->
      <FormSection title="YouTube Channel">
        <VTextField :v="v$.formModel.youtube" placeholder="YouTube Channel URL" />
      </FormSection>

      <!-- BombBomb -->
      <FormSection title="Do you have BombBomb videos?">
        <VRadio :v="v$.formModel.hasBombBombVideos" />
      </FormSection>
      <FormSection v-if="formModel.hasBombBombVideos === 'Yes'" title="BombBomb Credentials">
        <VTextField :v="v$.formModel.bombbombUsername" placeholder="Username" autocomplete="off" />
        <VTextField :v="v$.formModel.bombbombPassword" placeholder="Password" autocomplete="off" />
      </FormSection>

      <!-- RealScout -->
      <FormSection title="Include RealScout Action Plans?" :helperText="`(Additional $${pricing.REAL_SCOUT.toLocaleString('en')})`" chipText="RealScout - Platform Pairing Add-On">
        <p class="text-sm font-medium">Note: By making this selection you agree that you are currently using RealScout</p>
        <ToggleSwitch v-model="formModel.includeRealScoutActionPlans" />
      </FormSection>

      <!-- Fello -->
      <FormSection title="Include Fello Action Plans?" :helperText="`(Additional $${pricing.FELLO.toLocaleString('en')})`" chipText="Fello - Platform Pairing Add-On">
        <p class="text-sm font-medium">Note: By making this selection you agree that you are currently using Fello</p>
        <ToggleSwitch v-model="formModel.includeFelloActionPlans" />
      </FormSection>

      <!-- Conversion Monster -->
      <FormSection title="Include Conversion Monster Action Plans?" :helperText="`(Additional $${pricing.CONVERSION_MONSTER.toLocaleString('en')})`" chipText="Conversion Monster - Platform Pairing Add-On">
        <p class="text-sm font-medium">Note: By making this selection you agree that you are currently using Conversion Monster</p>
        <ToggleSwitch v-model="formModel.includeConversionMonsterActionPlans" />
      </FormSection>

      <!-- Ylopo -->
      <FormSection title="Include Ylopo Action Plans?" :helperText="`(Additional $${pricing.YLOPO.toLocaleString('en')})`" chipText="Ylopo - Platform Pairing Add-On">
        <p class="text-sm font-medium">Note: By making this selection you agree that you are currently using Ylopo</p>
        <ToggleSwitch v-model="formModel.includeYlopoActionPlans" />
      </FormSection>

      <!-- Ylopo Follow-up Questions -->
      <div v-if="formModel.includeYlopoActionPlans">
        <FormSection title="Is your Ylopo search site live?">
          <VRadio :v="v$.formModel.ylopoUserSearchSiteLive" />
        </FormSection>
        <FormSection title="Is your Ylopo branded website live?">
          <VRadio :v="v$.formModel.ylopoUserBrandedSiteLive" :options="['Yes', 'No', 'Not using']" />
        </FormSection>
        <FormSection v-if="formModel.ylopoUserBrandedSiteLive === 'Yes'" title="Ylopo branded website URL">
          <VTextField :v="v$.formModel.ylopoUserBrandedSiteUrl" placeholder="URL" autocomplete="off" />
        </FormSection>
      </div>

      <!-- FUB Pixel -->
      <FormSection title="Is the Follow Up Boss Pixel installed on your website?">
        <VRadio :v="v$.formModel.fubPixelInstalled" :options="['Yes', 'No', 'Not compatible']" />
      </FormSection>

      <!-- Website Backends -->
      <FormSection v-if="formModel.fubPixelInstalled === 'No' && !formModel.includeYlopoActionPlans" class="inline-block" title="Courtesy service for installing the Follow Up Boss Pixel">
        <p class="text-sm text-gray-700">If you have a website backend and would like us to attempt the installation of the Follow Up Boss pixel for you, please provide your site's credentials below.<br><br><strong>Please note</strong> we offer this install service as a courtesy only and is not an objective step in the FUB revamp service we provide.<br><br><strong>Please note</strong> not every website is compatible with the Follow Up Boss Pixel and some site providers even reject the install of 3rd party scripts. Often we don't know compatibility issues until we attempt to install.<br><br>If you prefer to install the FUB pixel yourself you can refer to <a class="link" href="https://help.followupboss.com/hc/en-us/sections/1500001949321-Follow-Up-Boss-Pixel" target="_blank">Follow Up Boss Pixel documentation</a></p>
        <div v-for="(websiteBackend, idx) in formModel.websiteBackends" :key="`website_backend_${idx}`" class="bg-gray-100 p-5 mt-2">
          <p v-if="idx !== 0" class="cursor-pointer" @click="removeBackend(idx)"><font-awesome-icon icon="fa-solid fa-times" /> Remove</p>
          <input type="text" v-model="websiteBackend.url" class="form-input" placeholder="Backend URL" />
          <br>
          <input type="text" v-model="websiteBackend.username" class="form-input" placeholder="Username" />
          <br>
          <input type="text" v-model="websiteBackend.password" class="form-input" placeholder="Password" />
        </div>
        <p class="mt-5 font-semibold text-sm">Need the FUB pixel added to multiple websites?</p>
        <a @click="addBackend" class="text-site-purple font-semibold mt-1 inline-block underline cursor-pointer">
          <font-awesome-icon icon="fa-solid fa-plus-circle" />
          Add Website
        </a>
      </FormSection>

      <!-- SMS Add-on -->
      <FormSection title="Include add-on SMS Drips?" :helperText="`(Additional $${pricing.SMS_DRIPS.toLocaleString('en')})`" chipText="SMS Add-On">
        <p class="mt-2 text-sm font-medium">If you currently use either <strong>Texting Betty</strong>, <strong>Leadngage</strong>, or <strong>StreetText</strong> for automated texting in FUB, we can add our SMS Drips to the setup we provide for an additional ${{ pricing.SMS_DRIPS }}.</p>
        <p class="mt-2 text-sm font-medium">Note: You can always sign up for this add-on at a later time if, in the future, you sign up for either Texting Betty, Leadngage or StreetText.</p>
        <p class="mt-2 text-sm font-medium">By making this selection you agree that you <strong>currently</strong> are using either <strong>Texting Betty</strong>, <strong>Leadngage</strong>, or <strong>StreetText</strong>.</p>
        <ToggleSwitch v-model="formModel.includeSMSDrips" />

        <FormSection v-if="formModel.includeSMSDrips" :embed="true" title="Texting automation service you use">
          <VRadio :v="v$.formModel.textingService" :options="['Texting Betty', 'Leadngage', 'StreetText']" />
        </FormSection>
      </FormSection>

      <!-- Revamp Extended Add-on -->
      <FormSection title='Include add-on "Revamp Extended"?' :helperText="`(Additional $${pricing.REVAMP_EXTENDED.toLocaleString('en')})`" chipText="Revamp Extended Add-On">
        <button class="link" @click="showRevampExtendedModal = true"><font-awesome-icon icon="fa-solid fa-info-circle" /> see what's included</button>
        <ToggleSwitch v-model="formModel.includeRevampExtended" />
        <Modal v-if="showRevampExtendedModal" @close="showRevampExtendedModal = false">
          <RevampExtended crm="Follow Up Boss" :price="pricing.REVAMP_EXTENDED.toLocaleString('en')" />
        </Modal>
      </FormSection>

      <!-- Add this section after the Revamp Extended Add-on and before Project Price -->
      <FormSection title='Include Ponds add-on?' :helperText="`(Additional $${pricing.PONDS_ADD_ON.toLocaleString('en')})`" chipText="Ponds Add-On">
        <p>9 best practice ponds w/ cooresponding action plans for managing new leads, re-engagements, re-inquiries, resurrections, vendors, off markets, and referral agents. <strong class="text-sm">(only available to FUB accounts that have more than one user)</strong></p>
        <ToggleSwitch v-model="formModel.includePondsAddOn" />
      </FormSection>

      <!-- Add this section after the Ponds add-on and before Project Price -->
      <FormSection title="Include add-on Lender Action Plans?" :helperText="`(Additional $${pricing.LENDER_ADD_ON.toLocaleString('en')})`" chipText="Lender Add-On">
        <p class="text-sm font-medium">Note: We do not provide Spanish translations for lender action plans at this time.</p>
        <p class="text-sm font-medium">Note: By making this selection you agree to the terms that the lenders in your Follow Up Boss account are in fact "Lender" type users. The lender action plans will not work otherwise.</p>
        <ToggleSwitch v-model="formModel.includeLenderAddOn" />

        <FormSection v-if="formModel.includeLenderAddOn" title="Do all lenders in your account work for the same mortgage company?" :embed="true">
          <VRadio :v="v$.formModel.singleMortgageCompany" />
        </FormSection>

        <FormSection v-if="formModel.singleMortgageCompany === 'Yes'" title="Lender Website" :embed="true">
          <VTextField :v="v$.formModel.lenderWebsiteUrl" placeholder="Website URL" />
        </FormSection>

        <FormSection
          v-if="formModel.singleMortgageCompany === 'Yes'"
          title="Online Reviews for Lender"
          helperText="(Please provide any URLs to reviews i.e Google, Facebook etc. Comma-seperated values)"
          :embed="true"
        >
          <VTextField :v="v$.formModel.lenderOnlineReviews" placeholder="URL" />
        </FormSection>
      </FormSection>

      <!-- Add this section after the Lender Action Plans and before Project Price -->
      <FormSection title="Private Team Training Call (1 hour)" :helperText="`(Additional $${pricing.PRIVATE_TEAM_TRAINING_CALL.toLocaleString('en')})`" chipText="Private Team Training Add-On">
        <p class="text-sm">One additional training call that your entire team may attend. * All other training calls are limited to two ambassadors from your team.</p>
        <ToggleSwitch v-model="formModel.includePrivateTeamTrainingCall" />
      </FormSection>

      <FormSection title="Project Price">
        <p class="text-xl mt-5">{{ totalDollarAmount }}</p>
        <p class="mt-2"><span class="link underline text-base font-normal" @click="showPriceBreakdownModal = true"><font-awesome-icon icon="fa-solid fa-info-circle" /> see price breakdown</span></p>
      </FormSection>

      <!-- Add this section after the Project Price section -->
      <FormSection title="Setup Activation">
        <p class="mt-5">After the setup of your new action plans and automations is complete, we will notify you via email. At this stage, you can either approve immediate activation or opt for a review period to evaluate the setup before it goes live.</p>
        <p class="mt-5"><strong>NOTE:</strong> Balance payment for the setup is due within two weeks from the setup completion date, irrespective of whether you choose immediate activation or a review period.</p>
      </FormSection>

      <!-- Add this section after the Setup Activation section -->
      <FormSection title="Terms Agreement">
        <div class="mt-5">
          <div :class="{ 'border border-red-500 p-2 rounded': v$.formModel.agreeToConditions.$error }">
            <p v-if="v$.formModel.agreeToConditions.$error" class="field-error text-xs text-red-500">{{ v$.formModel.agreeToConditions.$errors[0].$message }}</p>
            <input type="checkbox" v-model="formModel.agreeToConditions" true-value="agree" false-value="" />
            <label class="ml-2">I agree to <a class="link text-base font-normal" @click="showConditionAgreement = true"><font-awesome-icon icon="fa-solid fa-info-circle" /> these conditions</a></label>
          </div>
        </div>
      </FormSection>

      <!-- Add this section after the Terms Agreement section -->
      <FormSection title="Payment">
        <p class="my-5 text-lg">Due now is a payment of {{ totalDollarAmount }}</p>
        <p class="font-semibold">Billing Information</p>
        <VTextField :v="v$.formModel.billing.firstName" placeholder="First Name" />
        <VTextField :v="v$.formModel.billing.lastName" placeholder="Last Name" />
        <VTextField :v="v$.formModel.billing.email" placeholder="Email" />
        <VTextField :v="v$.formModel.billing.addressLine1" placeholder="Address Line 1" />
        <VTextField :v="v$.formModel.billing.addressLine2" placeholder="Address Line 2" />
        <CountrySelect
          v-model="formModel.billing.country"
          :errorMessage="v$.formModel.billing.country.$error ? v$.formModel.billing.country.$errors[0].$message : ''"
        />
        <VTextField :v="v$.formModel.billing.city" placeholder="City" />
        <StateSelect
          v-model="formModel.billing.state"
          :errorMessage="v$.formModel.billing.state.$error ? v$.formModel.billing.state.$errors[0].$message : ''"
          :showProvinces="formModel.billing.country === 'Canada'"
        />
        <VTextField 
          :v="v$.formModel.billing.zip" 
          :placeholder="formModel.billing.country === 'Canada' ? 'Postal Code' : 'Zip Code'" 
        />
        <VTextField :v="v$.formModel.billing.phone" placeholder="Phone" />
        <p class="mt-5 font-semibold">Card Information</p>
        <SquarePayment ref="squarePayment" />
        <button @click="submit" :disabled="!canSubmit" class="button mt-5">{{ submitText }}</button>
        <p v-if="v$.$error" class="mt-2 text-red-500">
          Oops... There is an issue with the form. Check fields for errors.
        </p>
      </FormSection>

      <ConditionAgreementModal v-if="showConditionAgreement" @close="showConditionAgreement = false" />
    </div>
    <FormSection v-else>
      <button @click="submit" class="button mt-5">Submit</button>
    </FormSection>
    <Modal v-if="showModal" size="md">
      <div v-if="loading">
        <p class="text-center">Submitting...</p>
      </div>
      <div v-else-if="error">
        <p class="text-center">There was an error submitting your information.</p>
      </div>
      <div v-else>
        <p class="text-center text-lg font-medium">Your info was submitted successfully!</p>
        <p class="text-center mt-5">We look forward to getting your account setup with the Kathleen Black Follow Up Boss Ecosystem.</p>
        <p class="text-center mt-5">A Kee Technology Solutions team member will be reaching out to you shortly to discuss the next steps.</p>
        <div class="mt-5 text-center border p-5">
          <p class="text-sm font-medium">Curious about enhancing the Kathleen Black Ecosystem with automated drip campaigns?</p>
          <p class="text-sm mt-5">Power up your ecosystem with automated drip campaigns that seamlessly compliment your active nurturing efforts with empathetic and personable messages that drive engagement and generate responses.</p>
          <button @click="proceedToPowerUp" class="button mt-5">Get the Power Up</button>
        </div>
        <p class="text-center text-sm mt-5">
          <router-link to="/kathleen-black-ecosystem" replace class="link">Done</router-link>
        </p>
      </div>
    </Modal>
    <PowerUpModal v-if="showPowerUpLearnMore" @close="showPowerUpLearnMore = false" />
    <PriceBreakdownModal
      v-if="showPriceBreakdownModal"
      @close="showPriceBreakdownModal = false"
      :lineItems="lineItems"
      :total="total"
    />
  </FormPageLayout>
</template>

<script>
import PowerUpModal from './PowerUpModal.vue'
import RevampExtended from '../../common/revamp/RevampExtended.vue'
import ConditionAgreementModal from '../../common/ConditionAgreementModal.vue'
import SquarePayment from '../../common/SquarePayment.vue'
import { required, requiredIf, formValidationMixin } from '@/mixins/formValidateMixin'
import pricingMixin from '@/mixins/pricingMixin'
import { SETUP_TYPE } from '../../../constants'


export default {
  mixins: [formValidationMixin, pricingMixin],
  props: {
    keeId: {
      type: String,
      required: true
    }
  },
  components: {
    PowerUpModal,
    RevampExtended,
    ConditionAgreementModal,
    SquarePayment
  },
  data() {
    return {
      setupType: SETUP_TYPE.KATHLEEN_BLACK_POWER_UP,
      showPriceBreakdownModal: false,
      showPowerUpLearnMore: false,
      showRevampExtendedModal: false,
      showModal: false,
      loading: false,
      error: '',
      showConditionAgreement: false,
      formModel: {
        websites: [{ url: '' }],
        onlineReviews: [{ url: '' }],
        brokerage: '',
        businessAddress: {
          addressLine1: '',
          addressLine2: '',
          country: '',
          city: '',
          state: '',
          zip: '',
        },
        multiMarketServicing: '',
        youtube: '',
        crmUsername: '',
        crmPassword: '',
        teamLeader: '',
        contractsMgm: '',
        leadPool: '',
        dbManager: '',
        usesLeadConversionPartner: '',
        leadConversionPartner: '',
        adminFrontDesk: '',
        includePowerUp: '',
        hasBombBombVideos: '',
        bombbombUsername: '',
        bombbombPassword: '',
        fubPixelInstalled: '',
        includeRealScoutActionPlans: false,
        includeFelloActionPlans: false,
        includeConversionMonsterActionPlans: false,
        includeYlopoActionPlans: false,
        ylopoUserSearchSiteLive: '',
        ylopoUserBrandedSiteLive: '',
        ylopoUserBrandedSiteUrl: '',
        websiteBackends: [{ 
          url: '',
          username: '',
          password: ''
        }],
        includeSMSDrips: false,
        textingService: '',
        includeRevampExtended: false,
        includePondsAddOn: false,
        includeLenderAddOn: false,
        singleMortgageCompany: '',
        lenderWebsiteUrl: '',
        lenderOnlineReviews: '',
        includePrivateTeamTrainingCall: false,
        agreeToConditions: '',
        billing: {
          firstName: '',
          lastName: '',
          email: '',
          addressLine1: '',
          addressLine2: '',
          country: '',
          city: '',
          state: '',
          zip: '',
          phone: '',
        },
      }
    }
  },
  validations() {
    const requiredIfPowerUp = { required: requiredIf(this.formModel.includePowerUp === 'Yes') }

    return {
      formModel: {
        brokerage: requiredIfPowerUp,
        crmUsername: { required },
        crmPassword: { required },
        teamLeader: { required },
        contractsMgm: { required },
        leadPool: { required },
        dbManager: { required },
        usesLeadConversionPartner: { required },
        leadConversionPartner: { required: requiredIf(this.usesLeadConversionPartner === 'Yes') },
        adminFrontDesk: { required },
        includePowerUp: { required },
        businessAddress: {
          addressLine1: requiredIfPowerUp,
          addressLine2: {},
          country: requiredIfPowerUp,
          city: requiredIfPowerUp,
          state: requiredIfPowerUp,
          zip: requiredIfPowerUp
        },
        multiMarketServicing: requiredIfPowerUp,
        youtube: {},
        hasBombBombVideos: requiredIfPowerUp,
        bombbombUsername: { required: requiredIf(this.formModel.hasBombBombVideos === 'Yes') },
        bombbombPassword: { required: requiredIf(this.formModel.hasBombBombVideos === 'Yes') },
        fubPixelInstalled: requiredIfPowerUp,
        ylopoUserSearchSiteLive: { required: requiredIf(this.formModel.includeYlopoActionPlans) },
        ylopoUserBrandedSiteLive: { required: requiredIf(this.formModel.includeYlopoActionPlans) },
        ylopoUserBrandedSiteUrl: { required: requiredIf(this.formModel.ylopoUserBrandedSiteLive === 'Yes') },
        textingService: { required: requiredIf(this.formModel.includeSMSDrips) },
        includeLenderAddOn: { required },
        singleMortgageCompany: { required: requiredIf(this.formModel.includeLenderAddOn) },
        lenderWebsiteUrl: { required: requiredIf(this.formModel.singleMortgageCompany === 'Yes') },
        lenderOnlineReviews: {},
        includePrivateTeamTrainingCall: { required },
        agreeToConditions: { required },
        billing: {
          firstName: { required },
          lastName: { required },
          email: {},
          addressLine1: { required },
          addressLine2: {},
          country: { required },
          city: { required },
          state: { required },
          zip: { required },
          phone: {}
        },
      }
    }
  },
  computed: {
    canSubmit() {
      return !this.isSubmitting
    },
    submitText() {
      return `Submit and pay`
    }
  },
  methods: {
    async submit() {
      this.error = ''

      if (!(await this.v$.formModel.$validate())) return
      
      this.loading = true
      this.showModal = true
      try {
        const response = await this.$service.api('/fub/kathleen-black/setup-data').post({
          keeId: this.keeId,
          ...this.formModel
        })
        console.log(response)
      } catch (error) {
        this.error = this.$service.getErrorMessage(error)
      } finally {
        this.loading = false
      }
    },
    addWebsite() {
      this.formModel.websites.push({ url: '' });
    },
    removeWebsite(idx) {
      this.formModel.websites.splice(idx, 1);
    },
    addOnlineReview() {
      this.formModel.onlineReviews.push({ url: '' });
    },
    removeOnlineReview(idx) {
      this.formModel.onlineReviews.splice(idx, 1);
    },
    addBackend() {
      this.formModel.websiteBackends.push({ 
        url: '',
        username: '',
        password: ''
      });
    },
    removeBackend(idx) {
      this.formModel.websiteBackends.splice(idx, 1);
    },
  },
}
</script>
