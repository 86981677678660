<template>
  <div class="inline-block">
    <div class="flex items-center space-x-4">
      <img src="/logo.png" class="inline-block w-10" />  
      <div v-if="!square" class="text-xs tracking-widest text-left text-kts-gold uppercase font-light"><span class="text-lg font-medium">Kee</span><br>Technology Solutions</div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    square: {
      type: Boolean,
      default: false
    }
  }
}
</script>