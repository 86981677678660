<template>
  <StandardPageLayout>
    <div v-if="personLoading" class="flex flex-col items-center justify-center">
      <font-awesome-icon icon="fa-solid fa-spinner" spin class="text-site-purple" style="font-size: 60px;"/>
    </div>
    <div v-else-if="personError || !balanceAmount" class="flex flex-col items-center justify-center text-center">
      <p class="text-3xl text-site-purple font-semibold">Something went wrong... 😔</p>
      <div>
        <p class="text-xl pt-5">There was an isssue retrieving your information.</p>
        <p class="text-xl pt-5">Please contact <MailToLink subject="Balance Payment Form Error" body="The balance payment form did not successfully load" /></p>
      </div>
    </div>
    <div v-else-if="paidBalance" class="flex flex-col items-center justify-center text-center">
      <p class="text-2xl text-site-purple font-semibold">Our records indicate you previously paid the balance.</p>
      <p class="text-lg">No further action is required.</p>
    </div>
    <div v-else>
      <section>
        <p class="text-lg font-semibold">Balance Payment</p>
        <p class="text-lg font-semibold">Amount: {{balanceAmount}} <span class="text-sm font-normal">(USD)</span></p>
        <div class="inline-block bg-gray-100 p-5 rounded pt-3 mt-2">
          <p class="font-semibold">Billing Information</p>
          <VTextField :v="v$.formModel.billing.firstName" placeholder="First Name" />
          <VTextField :v="v$.formModel.billing.lastName" placeholder="Last Name" />
          <VTextField :v="v$.formModel.billing.addressLine1" placeholder="Address Line 1" />
          <VTextField :v="v$.formModel.billing.addressLine2" placeholder="Address Line 2" />
          <CountrySelect
            v-model="formModel.billing.country"
            :errorMessage="v$.formModel.billing.country.$error ? v$.formModel.billing.country.$errors[0].$message : ''"
          />
          <VTextField :v="v$.formModel.billing.city" placeholder="City" />
          <StateSelect
            v-model="formModel.billing.state"
            :errorMessage="v$.formModel.billing.state.$error ? v$.formModel.billing.state.$errors[0].$message : ''"
            :showProvinces="formModel.billing.country === 'Canada'"
          />
          <VTextField :v="v$.formModel.billing.zip" :placeholder="formModel.billing.country === 'Canada' ? 'Postal Code' : 'Zip Code'" />
          <VTextField :v="v$.formModel.billing.phone" placeholder="Phone" />
          <p class="mt-5 font-semibold">Card Information</p>
          <SquarePayment ref="squarePayment" />
        </div>
      </section>
      <button @click="submit" :disabled="isSubmitting" class="button mt-5">Submit {{balanceAmount}} Payment</button>
      <p v-if="v$.$error" class="mt-2 text-red-500">
        Oops... There is an issue with the form. Check fields for errors.
      </p>
      <SubmittingModal
        v-if="isSubmitting"
        @close="(isSubmitting = false)"
        :loading="loading"
        :errorMessage="error"
        :receiptUrl="receiptUrl"
      >
        <template #success>
          <div>
            <p class="text-3xl text-site-purple font-semibold">Thank you!</p>
            <p class="text-xl pt-5">The remaining balance of <strong class="font-semibold">{{balanceAmount}}</strong> has been paid.</p>
          </div>
        </template>
      </SubmittingModal>
    </div>
  </StandardPageLayout>
</template>
<script>
import { required, formValidationMixin } from '@/mixins/formValidateMixin'
import { SETUP_TYPE } from '../../constants'

export default {
  mixins: [formValidationMixin],
  props: ['keeId', 'setupType'],
  data() {
    return {
      personError: false,
      personLoading: true,
      error: "",
      loading: false,
      isSubmitting: false,
      paidBalance: false,
      depositAmount: "",
      receiptUrl: "",
      formModel: {
        billing: {
          firstName: "",
          lastName: "",
          addressLine1: "",
          addressLine2: "",
          country: "",
          city: "",
          state: "",
          zip: "",
          phone: ""
        }
      }
    }
  },
  validations() {
    return {
      formModel: {
        billing: {
          firstName: { required },
          lastName: { required },
          addressLine1: { required },
          addressLine2: {},
          country: { required },
          city: { required },
          state: { required },
          zip: { required },
          phone: {}
        },
      },
    };
  },
  computed: {
    balanceAmount() {
      return this.depositAmount ? `$${this.depositAmount.toLocaleString('en')}` : ""
    },
  },
  async mounted() {
    try {
      const { meta } = await this.$service.getPerson(this.keeId)

      let paidBalance
      let depositAmount

      switch (this.setupType) {
        case SETUP_TYPE.FUB_AGENT_REVAMP:
          paidBalance = meta.paidBalance
          depositAmount = meta.depositAmount
          break
        case SETUP_TYPE.TC_ASSIST:
          paidBalance = meta.paidTCBalance
          depositAmount = meta.tcDeposit
          break
      }

      this.paidBalance = paidBalance
      this.depositAmount = depositAmount
      this.personLoading = false
    } catch (e) {
      this.personError = this.$service.getErrorMessage(e)
      this.personLoading = false
    }
  },
  methods: {
    async submit() {
      if (!(await this.v$.$validate())) return

      try {
        const { locationId, sourceId } = await this.$refs.squarePayment.tokenize()
        this.error = ""
        this.isSubmitting = true
        this.loading = true

        let note = 'Balance Payment'

        switch (this.setupType) {
          case SETUP_TYPE.TC_ASSIST:
            note = 'TC Balance Payment'
            break
        }

        const { receiptUrl } = await this.$service.payBalance({
          keeId: this.keeId,
          meta: {
            setupType: this.setupType
          },
          payment: {
            note,
            billing: this.formModel.billing,
            locationId,
            sourceId,
          },
        })
        
        this.receiptUrl = receiptUrl
        this.loading = false
      } catch (e) {
        this.loading = false
        this.error = this.$service.getErrorMessage(e)
      }
    },
  },
}
</script>
<style scoped>
section {
  @apply mt-10;
}

label {
  @apply mr-2;
}

.field {
  @apply mt-2;
}

input[type=text],
input[type=password],
select {
  @apply border rounded py-1 px-2 w-64;
}

input[type=radio] {
  @apply mr-2;
}
</style>