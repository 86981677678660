<template>
  <FormPageLayout>
    <div v-if="personError">
      <p class="text-center mt-10 text-xl">Could not find account.</p>
    </div>
    <div v-else-if="personLoaded">
      <p class="text-2xl font-semibold text-site-purple">Thanks for signing up!</p>
      <p class="text-lg mt-5">
        We will need the following information before we can start revamping your
        FUB.
      </p>
      <!-- Follow Up Boss Credentials -->
      <FormSection title="Follow Up Boss Credentials" helperText="(Owner Account)">
        <VTextField :v="v$.formModel.crmUsername" placeholder="Username" autocomplete="off" />
        <VTextField :v="v$.formModel.crmPassword" placeholder="Password" autocomplete="off" />
      </FormSection>
      <!-- Website URL(s) -->
      <FormSection title="Website URL">
        <div v-for="(website, idx) in formModel.websites" :key="`website_${idx}`">
          <input type="text" v-model="website.url" class="form-input" placeholder="http://mywebsite.com" />
          <font-awesome-icon v-if="idx !== 0" class="cursor-pointer ml-2" @click="removeWebsite(idx)"
            icon="fa-solid fa-times" />
        </div>
        <p class="mt-5 font-semibold text-sm">Have multiple websites?</p>
        <a @click="addWebsite" class="text-site-purple font-semibold mt-1 inline-block underline cursor-pointer">
          <font-awesome-icon icon="fa-solid fa-plus-circle" />
          Add Website
        </a>
      </FormSection>
      <!-- Online Reviews -->
      <FormSection title="Online Reviews"
        helperText="(Please provide any URLs to your online reviews i.e Google, Zillow, Facebook etc.)">
        <div v-for="(onlineReview, idx) in formModel.onlineReviews" :key="`online_review_${idx}`">
          <input type="text" v-model="onlineReview.url" class="form-input" placeholder="http://review-url.com" />
          <font-awesome-icon v-if="idx !== 0" class="cursor-pointer ml-2" @click="removeOnlineReview(idx)"
            icon="fa-solid fa-times" />
        </div>
        <p class="mt-5 font-semibold text-sm">Have more online review URLs?</p>
        <a @click="addOnlineReview" class="text-site-purple font-semibold mt-1 inline-block underline cursor-pointer">
          <font-awesome-icon icon="fa-solid fa-plus-circle" />
          Add Online Review URL
        </a>
      </FormSection>
      <!-- Brokerage -->
      <FormSection title="Brokerage">
        <VTextField :v="v$.formModel.brokerage" placeholder="i.e Keller Williams Realty" />
      </FormSection>
      <!-- Business Address -->
      <FormSection title="Business Address">
        <VTextField :v="v$.formModel.businessAddress.addressLine1" placeholder="Address Line 1" />
        <VTextField :v="v$.formModel.businessAddress.addressLine2" placeholder="Address Line 2" />
        <CountrySelect v-model="formModel.businessAddress.country"
          :errorMessage="v$.formModel.businessAddress.country.$error ? v$.formModel.businessAddress.country.$errors[0].$message : ''" />
        <VTextField :v="v$.formModel.businessAddress.city" placeholder="City" />
        <StateSelect v-model="formModel.businessAddress.state"
          :errorMessage="v$.formModel.businessAddress.state.$error ? v$.formModel.businessAddress.state.$errors[0].$message : ''"
          :showProvinces="formModel.businessAddress.country === 'Canada'" />
        <VTextField :v="v$.formModel.businessAddress.zip"
          :placeholder="formModel.businessAddress.country === 'Canada' ? 'Postal Code' : 'Zip Code'" />
      </FormSection>
      <!-- Servicing Multiple Markets -->
      <FormSection title="Do you service more than one market?" helperText="(multiple states, provinces, or regions)">
        <VRadio :v="v$.formModel.multiMarketServicing" />
      </FormSection>
      <!-- YouTube Channel -->
      <FormSection title="YouTube Channel">
        <VTextField :v="v$.formModel.youtube" placeholder="YouTube Channel URL" />
      </FormSection>
      <!-- BombBomb -->
      <!-- BombBomb -->
      <FormSection title="Do you have BombBomb videos?">
        <VRadio :v="v$.formModel.hasBombBombVideos" />
      </FormSection>
      <FormSection v-if="formModel.hasBombBombVideos === 'Yes'" title="BombBomb Credentials">
        <VTextField :v="v$.formModel.bombbombUsername" placeholder="Username" autocomplete="off" />
        <VTextField :v="v$.formModel.bombbombPassword" placeholder="Password" autocomplete="off" />
      </FormSection>
      <!-- Ylopo Users -->
      <FormSection v-if="person.meta.includeYlopoActionPlans" title="Is your Ylopo search site live?">
        <VRadio :v="v$.formModel.ylopoUserSearchSiteLive" />

        <FormSection title="Is your Ylopo branded website live?" :embed="true">
          <VRadio :v="v$.formModel.ylopoUserBrandedSiteLive" :options="['Yes', 'No', 'Not using']" />
        </FormSection>

        <FormSection v-if="formModel.ylopoUserBrandedSiteLive === 'Yes'" title="Branded Website URL" :embed="true">
          <VTextField :v="v$.formModel.ylopoUserBrandedSiteUrl" placeholder="URL" autocomplete="off" />
        </FormSection>
      </FormSection>
      <!-- FUB Pixel -->
      <FormSection title="Is the Follow Up Boss Pixel installed on your website?">
        <VRadio :v="v$.formModel.fubPixelInstalled" :options="['Yes', 'No', 'Not compatible']" />

        <FormSection v-if="!person.meta.includeYlopoActionPlans && formModel.fubPixelInstalled === 'No'"
          class="inline-block" title="Courtesy service for installing the Follow Up Boss Pixel" :embed="true">
          <p class="text-sm text-gray-700">If you have a website backend and would like us to attempt the installation
            of
            the Follow Up Boss pixel for you, please provide your site's credentials below.<br><br><strong>Please
              note</strong> we offer this install service as a courtesy only and is not an objective step in the FUB
            revamp service we provide.<br><br><strong>Please note</strong> not every website is compatible with the
            Follow
            Up Boss Pixel and some site providers even reject the install of 3rd party scripts. Often we don't know
            compatibility issues until we attempt to install.<br><br>If you prefer to install the FUB pixel yourself you
            can refer to <a class="link"
              href="https://help.followupboss.com/hc/en-us/sections/1500001949321-Follow-Up-Boss-Pixel"
              target="_blank">Follow Up Boss Pixel documentation</a></p>
          <div v-for="(websiteBackend, idx) in formModel.websiteBackends" :key="`website_backend_${idx}`"
            class="bg-gray-100 p-5 pt-3 mt-5">
            <div v-if="idx !== 0" class="flex justify-end">
              <span class="cursor-pointer" @click="removeBackend(idx)">
                <font-awesome-icon icon="fa-solid fa-times" /> Remove
              </span>
            </div>
            <div class="field">
              <input type="text" v-model="websiteBackend.url" placeholder="Backend URL" />
            </div>
            <div class="field">
              <input type="text" v-model="websiteBackend.username" placeholder="Username" />
            </div>
            <div class="field">
              <input type="text" v-model="websiteBackend.password" placeholder="Password" />
            </div>
          </div>
          <p class="mt-5 font-semibold text-sm">
            Need the FUB pixel added to multiple websites?
          </p>
          <a @click="addBackend" class="
            text-site-purple
            font-semibold
            mt-1
            inline-block
            underline
            cursor-pointer
          ">
            <font-awesome-icon icon="fa-solid fa-plus-circle" />
            Add Website
          </a>
        </FormSection>
      </FormSection>
      <!-- Lender Action Plans -->
      <FormSection v-if="person.meta.includeLenderAddOn"
        title="Do all lenders in your account work for the same mortgage company?">
        <VRadio :v="v$.formModel.singleMortgageCompany" />

        <FormSection v-if="formModel.singleMortgageCompany === 'Yes'" title="Lender Website" :embed="true">
          <VTextField :v="v$.formModel.lenderWebsiteUrl" placeholder="Website URL" />
        </FormSection>
        <FormSection v-if="formModel.singleMortgageCompany === 'Yes'" title="Online Reviews for Lender" helperText="(Please provide
              any URLs to reviews i.e Google, Facebook etc. Comma-seperated values)" :embed="true">
          <VTextField :v="v$.formModel.lenderOnlineReviews" placeholder="URL" />
        </FormSection>
      </FormSection>
      <!-- Setup activation notice -->
      <div class="p-10 mt-10 border bg-gray-100">
        <h3 class="text-lg font-semibold">Activating your new setup</h3>
        <p class="mt-5">After the setup of your new action plans and automations is complete, we will notify you via
          email. At this stage, you can either approve immediate activation or opt for a review period to evaluate the
          setup before it goes live.</p>
      </div>
      <FormSection>
        <div class="field" :class="{ 'field-block-error': v$.formModel.agreeToConditions.$error }">
          <p v-if="v$.formModel.agreeToConditions.$error" class="field-error text-xs text-red-500">
            {{ v$.formModel.agreeToConditions.$errors[0].$message }}
          </p>
          <input type="checkbox" v-model="formModel.agreeToConditions" true-value="agree" false-value="" />
          <label class="ml-2 font-medium">I agree to
            <a @click="showConditionAgreement = true" class="link">these conditions</a> <span class="font-light">(click
              the link to view terms and conditions)</span></label>
        </div>
        <button @click="submit" :disabled="!canSubmit" class="button mt-5">Submit</button>
        <p v-if="v$.$error" class="mt-2 text-red-500">
          Oops... There is an issue with the form. Check fields for errors.
        </p>
      </FormSection>
      <ConditionAgreementModal v-if="showConditionAgreement" @close="showConditionAgreement = false" />
      <SubmittingModal v-if="isSubmitting" @close="(isSubmitting = false)" :loading="loading" :errorMessage="error" :showNextSteps="true" :noPayment="true">
        <template #success>
          <div>
            <p class="text-3xl text-site-purple font-semibold">Sign up complete!</p>
            <p class="text-xl pt-5">Your information has been submitted successfully</p>
          </div>
        </template>
      </SubmittingModal>
    </div>
  </FormPageLayout>
</template>
<script>
import { required, requiredIf, formValidationMixin } from '@/mixins/formValidateMixin'
import _pick from 'lodash/pick'
import { SETUP_TYPE, STAGE_SETUP_TYPES } from '../../../constants';

const newWebsiteBackend = {
  url: "",
  username: "",
  password: "",
};
const newWebsite = {
  url: "",
};
const newOnlineReview = {
  url: ""
};
export default {
  mixins: [formValidationMixin],
  props: ["keeId"],
  data() {
    return {
      loading: false,
      error: '',
      person: {},
      personError: false,
      isSubmitting: false,
      showConditionAgreement: false,
      formModel: {
        crmUsername: "",
        crmPassword: "",
        websites: [{ ...newWebsite }],
        onlineReviews: [{ ...newOnlineReview }],
        youtube: "",
        brokerage: "",
        businessAddress: {
          addressLine1: "",
          addressLine2: "",
          country: "",
          city: "",
          state: "",
          zip: "",
        },
        singleMortgageCompany: "",
        lenderWebsiteUrl: "",
        lenderOnlineReviews: "",
        multiMarketServicing: "",
        hasBombBombVideos: "",
        bombbombUsername: "",
        bombbombPassword: "",
        ylopoUserSearchSiteLive: "",
        ylopoUserBrandedSiteLive: "",
        ylopoUserBrandedSiteUrl: "",
        fubPixelInstalled: "",
        websiteBackends: [{ ...newWebsiteBackend }],
        agreeToConditions: "",
      },
    };
  },
  validations() {
    const { meta } = this.person
    return {
      formModel: {
        crmUsername: { required },
        crmPassword: { required },
        youtube: {},
        brokerage: { required },
        businessAddress: {
          addressLine1: { required },
          addressLine2: {},
          country: { required },
          city: { required },
          state: { required },
          zip: { required },
        },
        multiMarketServicing: { required },
        hasBombBombVideos: { required },
        bombbombUsername: { required: requiredIf(this.formModel.hasBombBombVideos === "Yes") },
        bombbombPassword: { required: requiredIf(this.formModel.hasBombBombVideos === "Yes") },
        ylopoUserSearchSiteLive: { required: requiredIf(meta.includeYlopoActionPlans) },
        ylopoUserBrandedSiteLive: { required: requiredIf(meta.includeYlopoActionPlans) },
        ylopoUserBrandedSiteUrl: { required: requiredIf(this.formModel.ylopoUserBrandedSiteLive === "Yes") },
        fubPixelInstalled: { required },
        agreeToConditions: { required },
        singleMortgageCompany: { required: requiredIf(meta.includeLenderAddOn) },
        lenderWebsiteUrl: { required: requiredIf(this.formModel.singleMortgageCompany === 'Yes') },
        lenderOnlineReviews: {},
      },
    };
  },
  computed: {
    setupType() {
      return SETUP_TYPE.FUB_AGENT_REVAMP
    },
    canSubmit() {
      return !this.isSubmitting;
    },
    personLoaded() {
      return !!this.person.meta
    }
  },
  async created() {
    try {
      this.person = await this.$service.getPerson(this.keeId)
    } catch (e) {
      this.personError = true
    }
  },
  methods: {
    openRouteInNewWindow(routeName) {
      window.open(this.$router.resolve({ name: routeName }).href, '_blank')
    },
    addWebsite() {
      this.formModel.websites.push({ ...newWebsite });
    },
    removeWebsite(idx) {
      this.formModel.websites.splice(idx, 1);
    },
    addOnlineReview() {
      this.formModel.onlineReviews.push({ ...newOnlineReview });
    },
    removeOnlineReview(idx) {
      this.formModel.onlineReviews.splice(idx, 1);
    },
    addBackend() {
      this.formModel.websiteBackends.push({ ...newWebsiteBackend });
    },
    removeBackend(idx) {
      this.formModel.websiteBackends.splice(idx, 1);
    },
    buildNotes() {
      const { meta } = this.person

      let buildNotes = _pick(this.formModel, [
        'websites',
        'onlineReviews',
        'youtube',
        'brokerage',
        'businessAddress',
        'multiMarketServicing',
        'hasBombBombVideos',
        'bombbombUsername',
        'bombbombPassword',
        'ylopoUserSearchSiteLive',
        'ylopoUserBrandedSiteLive',
        'ylopoUserBrandedSiteUrl',
        'fubPixelInstalled',
        'websiteBackends',
        'singleMortgageCompany',
        'lenderWebsiteUrl',
        'lenderOnlineReviews',
      ])

      buildNotes.textingService = 'Refer to Manual Signp Notes'
      buildNotes.stageSetup = meta.customStages ? STAGE_SETUP_TYPES.CUSTOM : meta.zillowStages ? STAGE_SETUP_TYPES.ZILLOW_TWO_WAY_SYNC : STAGE_SETUP_TYPES.DEFAULT

      return { ...buildNotes, ...meta }
    },
    async submit() {
      if (!(await this.v$.$validate())) return

      try {
        this.error = ''
        this.loading = true;
        this.isSubmitting = true;
        const notes = this.buildNotes();

        await this.$service.manualSetupData({
          keeId: this.keeId,
          crmPassword: this.formModel.crmPassword,
          crmUsername: this.formModel.crmUsername,
          notes,
          businessAddress: this.formModel.businessAddress,
          meta: {
            setupType: this.setupType,
          }
        })

        this.loading = false
      } catch (e) {
        this.error = this.$service.getErrorMessage(e)
        this.loading = false
      }
    },
  },
};
</script>
<style scoped>
label {
  @apply mr-2;
}

.field {
  @apply mt-2;
}

input[type="radio"] {
  @apply mr-2;
}

.field-block-error {
  @apply inline-block border border-red-500 p-2 rounded;
}
</style>
