<template>
  <Modal @close="$emit('close')">
    <div class="max-w-2xl mx-auto">
      <h2 class="text-4xl text-center">{{ title }}</h2>
      <p class="text-lg leading-8 mt-5 text-gray-700" v-html="getStartedHtml" />
      <p class="text-lg leading-8 mt-5 text-gray-700">After reviewing your submission, we
        will email you with the next steps to get the project started. If you have questions you'd like answered, you
        can schedule a call via our <a :href="calendarUrl" target="_blank" class="link">consult call calendar</a>.</p>
      <form @submit.prevent="submit" class="flex flex-col items-center mt-5">
        <input type="text" v-model="firstName" class="form-input" placeholder="First Name"/>
        <input type="text" v-model="lastName" class="form-input" placeholder="Last Name" />
        <input type="email" v-model="email" class="form-input" placeholder="Email" />
        <input type="tel" v-model="phone" class="form-input" placeholder="Phone Number" />
        <button type="submit" :disabled='isDisabled'
          class="button mt-5"
          :class="{ 'opacity-50 cursor-not-allowed': isDisabled }">Submit</button>
        <p class="text-red-500 mt-2">{{ error }}</p>
      </form>
      <p class="text-sm text-center mt-2 font-light">I agree to be contacted by Kee Technology Solutions via call, email, and text. To opt out, you can reply ‘stop’ at any time or click the unsubscribe link in the emails. Message and data rates may apply.</p>
    </div>
  </Modal>
</template>
<script>
import { mapGetters } from 'vuex'
import { CALENDLY_CALENDARS, CALENDLY_UTM, SETUP_TYPE } from '../../../constants'
import { buildCalendarUrl } from '../../../utils'

export default {
  props: {
    setupType: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      error: "",
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
    };
  },
  computed: {
    ...mapGetters(['affiliateName']),
    title() {
      switch (this.setupType) {
        case SETUP_TYPE.TC_ASSIST: return 'TC Assist Sign Up'
        case SETUP_TYPE.FUB_LENDER_REVAMP: return 'Lender Sign Up'
        case SETUP_TYPE.BOSS_ASSIST: return 'BossAssist Sign Up'
        default: return 'Sign Up'
      }
    },
    getStartedHtml() {
      switch (this.setupType) {
        case SETUP_TYPE.TC_ASSIST: return "Get started with Kee Technology Solution's <strong>Transaction Coordinator Build</strong> by providing your info below."
        case SETUP_TYPE.BOSS_ASSIST: return "Get started with <strong>BossAssist</strong> by providing your billing contact info below."
        case SETUP_TYPE.FUB_LENDER_REVAMP: return "Get started with Kee Technology Solution's <strong>Follow Up Boss Lender Revamp</strong> by providing your info below."
        default: return "Get started with Kee Technology Solution's <strong>Follow Up Boss Revamp</strong> by providing your info below."
      }
    },
    isDisabled() {
      return this.loading || !this.firstName || !this.lastName || !this.email || !this.phone
    },
    calendarUrl() {
      return buildCalendarUrl({
        calendar: this.calendar,
        utm: this.calendarUtm
      })
    },
    calendar() {
      switch (this.setupType) {
        case SETUP_TYPE.TC_ASSIST: return CALENDLY_CALENDARS.TC_ASSIST_DISCOVERY
        case SETUP_TYPE.BOSS_ASSIST: return CALENDLY_CALENDARS.BOSS_ASSIST_DISCOVERY
        case SETUP_TYPE.FUB_LENDER_REVAMP: return CALENDLY_CALENDARS.LENDER_DISCOVERY
        default: return CALENDLY_CALENDARS.DISCOVERY
      }
    },
    calendarUtm() {
      switch (this.setupType) {
        case SETUP_TYPE.TC_ASSIST: return CALENDLY_UTM.TC_ASSIST_DISCOVERY
        case SETUP_TYPE.BOSS_ASSIST: return CALENDLY_UTM.BOSS_ASSIST_DISCOVERY
        case SETUP_TYPE.FUB_LENDER_REVAMP: return CALENDLY_UTM.LENDER_DISCOVERY
        default: return CALENDLY_UTM.DISCOVERY
      }
    },
    setupFormPath() {
      switch (this.setupType) {
        case SETUP_TYPE.TC_ASSIST: return '/tc-assist/sign-up'
        case SETUP_TYPE.BOSS_ASSIST: return '/boss-assist/sign-up'
        case SETUP_TYPE.FUB_LENDER_REVAMP: return '/lender/sign-up'
        default: return '/sign-up'
      }
    }
  },
  methods: {
    async submit() {
      try {
        this.error = ""
        this.loading = true

        const { keeId } = await this.$service.createSignup({
          affiliate: this.affiliateName,
          basicInfo: {
            firstName: this.firstName,
            lastName: this.lastName,
            email: this.email,
            phone: this.phone,
          },
          meta: {
            setupType: this.setupType,
          }
        })

        this.$router.push(`${this.setupFormPath}/${keeId}`)
      } catch (e) {
        this.error = this.$service.getErrorMessage(e)
        this.loading = false
      }
    },
  },
}
</script>
