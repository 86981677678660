<template>
  <StandardPageLayout>
    <p class="text-2xl font-semibold">Thanks for signing up!</p>
    <p class="text-lg mt-2">We will need the following information before we can start revamping your FUB.</p>
    <!-- Follow Up Boss Credentials -->
    <FormSection title="Follow Up Boss Credentials" helperText="(Owner Account)">
      <VTextField :v="v$.formModel.crmUsername" placeholder="Username" autocomplete="off" />
      <VTextField :v="v$.formModel.crmPassword" placeholder="Password" autocomplete="off" />
    </FormSection>
    <div class="flex justify-center">
      <button @click="submit" class="button">Submit</button>
    </div>
  </StandardPageLayout>
</template>

<script>
import { required, formValidationMixin } from '@/mixins/formValidateMixin'

export default {
  mixins: [formValidationMixin],
  props: {
    keeId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      formModel: {
        crmUsername: '',
        crmPassword: ''
      }
    }
  },
  validations() {
    return {
      formModel: {
        crmUsername: { required },
        crmPassword: { required }
      }
    }
  },
  methods: {
    async submit() {
      if (!(await this.v$.$validate())) return

      try {
        const response = await this.$service.api('/fub/kathleen-black/setup-data').post({
          keeId: this.keeId,
          ...this.formModel
        })
        console.log(response)
      } catch (error) {
        console.error(this.$service.getErrorMessage(error))
      }
    }
  }
}
</script>