<template>
  <LandingPageLayout @handleCTA="openSignUp" :navItems="navItems">
    <Hero ref="hero" :setupType="setupType" :calendarUrl="calendarUrl" />
    <Benefits />
    <PainPoints />
    <Testimonials />
    <WhatsIncluded :setupType="setupType" />
    <FAQ :faqList="faqList" />
    <Pricing @openSignUp="openSignUp" :basePrice="basePrice" />
    <OtherServices class="md:hidden" />
    <Schedule @openSignUp="openSignUp" :calendarUrl="calendarUrl" />
  </LandingPageLayout>
</template>
<script>
import LandingPageLayout from '../../layouts/LandingPageLayout'
import Hero from '../../common/landing/Hero.vue'
import Benefits from './Benefits.vue'
import PainPoints from './PainPoints.vue'
import Testimonials from './Testimonials.vue'
import WhatsIncluded from '../../common/WhatsIncluded.vue'
import FAQ from '../../common/landing/FAQ.vue'
import Pricing from '../../common/landing/Pricing'
import Schedule from '../../common/landing/Schedule'
import OtherServices from './OtherServices.vue'
import { mapGetters } from 'vuex'
import { SETUP_TYPE, CALENDLY_CALENDARS, CALENDLY_UTM } from '../../../constants.js'
import { getPricing } from '@/pricing'
import { buildCalendarUrl } from '../../../utils'

export default {
  components: {
    LandingPageLayout,
    Hero,
    Benefits,
    PainPoints,
    Testimonials,
    WhatsIncluded,
    FAQ,
    Pricing,
    Schedule,
    OtherServices,
  },
  props: {
    setupType: {
      type: String,
      required: false,
      default: SETUP_TYPE.FUB_AGENT_REVAMP
    }
  },
  computed: {
    ...mapGetters(['agentRevampFaq', 'affiliateName']),
    navItems() {
      let items = []

      if (this.setupType === SETUP_TYPE.FUB_AGENT_REVAMP) {
        items = items.concat([
          {
            text: 'Testimonials',
            scrollId: 'testimonials',
            showOnXLOnly: true
          }
        ])
      }

      items = items.concat([
        {
          text: "What's Included",
          scrollId: 'service'
        },
        {
          text: "Pricing",
          scrollId: 'pricing'
        },
        {
          text: 'Audit',
          to: '/audit'
        }
      ])

      if (this.setupType === SETUP_TYPE.FUB_AGENT_REVAMP) {
        items = items.concat([
          {
            text: 'Guides',
            subNavItems: [
              {
                text: 'Solo Agent Guide',
                to: '/solo'
              },
              {
                text: 'Team Lead Guide',
                to: '/team-lead'
              }
            ]
          }
        ])
      }

      return items
    },
    pricing() {
      return getPricing(this.setupType)
    },
    basePrice() {
      return this.pricing.BASE.toLocaleString('en')
    },
    faqList() {
      return this.agentRevampFaq
    },
    calendarUrl() {
      const calendar = CALENDLY_CALENDARS.DISCOVERY

      const utm = this.affiliateName ? CALENDLY_UTM.AFFILIATE_DISCOVERY(this.affiliateName) : CALENDLY_UTM.DISCOVERY

      return buildCalendarUrl({ calendar, utm })
    },
  },
  methods: {
    openSignUp() {
      this.$refs.hero.openSignUp()
    }
  },
}
</script>