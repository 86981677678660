<template>
  <Modal @close="$emit('close')">
    <h2 class="text-3xl text-center font-semibold">Automated Drip Campaigns</h2>
    <p class="mt-10 text-center text-2xl font-semibold">What's Included</p>
    <ul class="list-disc mt-5">
      <li><strong>New Buyer</strong> - 2 years of emails (approx. 29 emails)</li>
      <li><strong>New Zillow, Realtor, Trulia, Redfin</strong> - 2 years of emails (28 emails)</li>
      <li><strong>New Open House Lead</strong> - 2 years of emails (28 emails)</li>
      <li><strong>New Seller</strong> - 1 year of emails (approx. 17 emails)</li>
      <li><strong>Nurture Buyer</strong> - 3 years of emails (approx. 40 emails)</li>
      <li><strong>Nurture Seller</strong> - 3 years of emails (approx. 38 emails)</li>
      <li><strong>Post Closing</strong> - 11 years of emails (approx. 50 emails)</li>
      <li><strong>Back To Website</strong> - 6 months of emails (9 emails)</li>
      <li><strong>Stale</strong> - 12 months of emails (15 emails)</li>
      <li><strong>Sphere Nurture</strong> - 4 years of emails (approx. 21 emails)</li>
      <li><strong>Renter To Buyer Drip</strong> - 2.5 years of emails (27 emails) partial content courtesy of <a href="https://www.tooniceforsales.com/" class="text-site-purple font-semibold underline cursor-pointer" target="_blank">Barry Jenkins</a></li>
      <li><strong>New Rental Lead</strong> - 12 months of emails (18 emails)</li>
      <li><strong>Branded Website Action Plan</strong> - 6 months of emails (12 emails)</li>
      <li><strong>Home Anniversary Action Plan</strong> - Spans to year 10</li>
      <li><strong>Pausing Action Plans Based On Stage Change</strong></li>
      <li><strong>Birthday</strong> - 20 years (1 email a year - Not yet available in Spanish)</li>
    </ul>
    <p class="mt-10 text-center text-2xl font-semibold mb-5">Optional Add-Ons</p>
    <div v-for="addOn, idx in powerUpAddOns" :key="`add_on_${idx}`" class="p-4 text-center bg-gray-200 mt-1">
      <p class="text-lg font-semibold">{{ addOn.title }}</p>
      <p v-if="addOn.subtext" class="mt-2 text-sm">{{ addOn.subtext }}</p>
      <p class="text-lg mt-2">${{ addOn.price }}</p>
    </div>
  </Modal>
</template>
<script>
import { SETUP_TYPE } from '@/constants'
import { getPricing } from '@/pricing'
export default {
  computed: {
    pricing() {
      return getPricing(SETUP_TYPE.FUB_AGENT_REVAMP)
    },
    powerUpAddOns() {
      return [
        {
          title: 'Extended Action Plans',
          subtext: '* Additional action plans - Luxury Buyer and Seller, New Construction, Probate, Investor Buyer, Land Buyer, and more.',
          price: this.pricing.REVAMP_EXTENDED.toLocaleString('en')
        },
        {
          title: 'Platform Pairing',
          subtext: '* Additional action plans to support the following - Ylopo, RealScout, Fello, Conversion Monster',
          price: '100 per platform' 
        },
        {
          title: 'Ponds',
          subtext: "7 best practice ponds w/ corresponding action plans for managing new leads, re-engagements, re-inquiries, resurrections, vendors, off markets, and referral agents (only available to FUB accounts that have more than one user)",
          price: this.pricing.PONDS_ADD_ON.toLocaleString('en')
        },
        {
          title: 'SMS Drips',
          subtext: '* Must have Leadngage, Texting Betty, or StreetText',
          price: this.pricing.SMS_DRIPS.toLocaleString('en')
        },
        {
          title: 'Private Team Training Call (1 hour)',
          subtext: 'One additional training call that your entire team may attend. * All other training calls are limited to two ambassadors from your team.',
          price: this.pricing.PRIVATE_TEAM_TRAINING_CALL.toLocaleString('en')
        }
      ]
    }
  }
}
</script>