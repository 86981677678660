<template>
  <StandardPageLayout :transparentMain="true">
    <div class="max-w-lg mx-auto">
      <div class="bg-white p-8 sm:p-10 border-kts-gold rounded-xl border-8">
        <div v-if="error" class="text-center">
          <p class="text-xl">There was an isssue submitting your information.</p>
          <p class="mt-5">If the error persists or you need our assistance please contact <MailToLink subject="KTS Form Failure" body="I've encountered a form submission error." /></p>
          <button @click="error=false" class="bg-kts-gold mt-5 p-3 shadow-lg hover:opacity-90 text-xl font-semibold uppercase">Retry!</button>
        </div>
        <div v-else-if="success" class="text-center text-lg">
          <p>Thank you!</p>
          <p class="mt-2">Your info has been successfully submitted and an email has been sent to {{ formModel.email }}</p>
          <button @click="$router.replace(`/`)" class="bg-kts-gold mt-5 p-3 shadow-lg hover:opacity-90 text-xl font-semibold uppercase">Done</button>
        </div>
        <div v-else>
          <div v-if="titleHtml" v-html="titleHtml" />
          <h1 v-else class="text-2xl font-semibold">{{ title }}</h1>
          <ContentfulRTE :content="body" class="mt-5" />
          <form @submit.prevent="submit" class="flex flex-col font-medium mt-5">
            <input type="text" placeholder="First Name" v-model="formModel.firstName" />
            <input type="text" placeholder="Last Name" v-model="formModel.lastName" />
            <input type="text" placeholder="Email" v-model="formModel.email" />
            <input type="text" placeholder="Phone" v-model="formModel.phone" />
            <div v-if="loading" class="bg-kts-gold mt-5 p-3 shadow-lg hover:opacity-90 text-xl font-semibold uppercase text-center"><font-awesome-icon icon="fa-solid fa-spinner" spin /></div>
            <button v-else type="submit" :disabled="!canSubmit" class="bg-kts-gold mt-5 p-3 shadow-lg hover:opacity-90 text-xl font-semibold uppercase">{{ buttonText }}</button>
          </form>
        </div>
      </div>
    </div>
  </StandardPageLayout>
</template>
<script>
export default {
  props: {
    entry: {
      type: Object
    }
  },
  data() {
    return {
      error: false,
      loading: false,
      success: false,
      formModel: {
        name: "",
        email: "",
        phone: ""
      }
    }
  },
  created() {
    console.log(this.entry.fields.tag)
  },
  computed: {
    canSubmit() {
      const { firstName, lastName, email, phone } = this.formModel
      return !!firstName && !!lastName && !!email && !!phone
    },
    promo() {
      return this.entry.fields.tag
    },
    buttonText() {
      return this.entry.fields.buttonText
    },
    title() {
      return this.entry.fields.title
    },
    body() {
      // return documentToHtmlString(this.entry.fields.body)
      return this.entry.fields.body
    },
    customizations() {
      return this.entry.fields.customizations || null
    },
    titleHtml() {
      return this.customizations?.titleHtml
    },
  },
  methods: {
    async submit() {
      this.error = false
      this.loading = true
      const { firstName, lastName, email, phone } = this.formModel
      try {
        await this.$service.postPromo({
          promo: this.promo,
          firstName,
          lastName,
          email,
          phone
        })
        this.success = true
      } catch {
        this.loading = false
        this.error = true
      }
    }
  }
}
</script>
<style scoped>
button:disabled {
  @apply opacity-50 cursor-not-allowed;
}
input[type=text] {
  @apply border-2 px-5 py-1 mt-3 w-full h-12 text-lg font-medium;
}
input[type=text]:focus {
  @apply outline-none border-purple-400;
}
</style>