<template>
 <div v-if="revampExtended">
  <h1 class="text-2xl font-bold">{{ revampExtended.title }} - ${{ revampExtended.price }}</h1>
  <ContentfulRTE :html="revampExtended.shortDescription" />
  <ContentfulRTE :html="revampExtended.longDescription" />
 </div>
</template>
<script>
import { SETUP_TYPE } from '../../../constants'
import { documentToHtmlString } from '@contentful/rich-text-html-renderer'
export default {
  data() {
    return {
      revampExtended: null
    }
  },
  async created() {
    const entriesData = await this.$contentful.getEntries({
      limit: 1,
      content_type: 'setupType',
      'fields.code': SETUP_TYPE.FUB_AGENT_REVAMP,
      select: ['fields.addOnSections'],
      include: 10
    })

    const { title, price, shortDescription, longDescription } = entriesData.items[0].fields.addOnSections.find(section => section.fields.title === 'Revamp Extended').fields

    this.revampExtended = {
      title,
      price,
      shortDescription: documentToHtmlString(shortDescription),
      longDescription: documentToHtmlString(longDescription)
    }
  }
}
</script>
