import _cloneDeep from 'lodash/cloneDeep'
import _merge from 'lodash/merge'

const PRICING = {
  FUB_AGENT_REVAMP: {
    BASE: 900,
    CUSTOM_STAGES: 400,
    SPANISH_ACTION_PLANS: 600,
    LENDER_ADD_ON: 600,
    SMS_DRIPS: 500,
    SMS_DRIPS_CUSTOM_STAGES: 200,
    REVAMP_EXTENDED: 400,
    PONDS_ADD_ON: 400,
    PRIVATE_TEAM_TRAINING_CALL: 300,
    PLATFORM_PAIRING_ADD_ON: 100
  },
  FUB_LENDER_REVAMP: {
    BASE: 800,
    CUSTOM_STAGES: 400,
    SMS_DRIPS: 400,
    SMS_DRIPS_CUSTOM_STAGES: 200
  },
  TC_ASSIST: {
    BASE: 1200,
    FIRST_TC_SEAT: 800,
    ADDITIONAL_TC_SEAT: 200,
    CUSTOM_STAGES: 400,
  },
}

const LEGACY_PRICING_OVERRIDE = {
  FUB_AGENT_REVAMP: {
    BASE: 900
  }
}

export const getPricing = (setupType, legacyOverride = false) => (!legacyOverride ? _cloneDeep(PRICING) : _merge({}, PRICING, LEGACY_PRICING_OVERRIDE))[setupType]

export const bossAssistPricingTiers = [
  { description: 'First VA Sign Up', price: 1200 },
  { description: '2nd VA Sign Up', price: 1100 },
  { description: '3+ VA Sign Ups', price: 1000 },
]